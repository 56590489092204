import { MExpirableCache } from '@/_api/_storage/MExpirableCache'
import { GLOBAL_TIMES } from '@/_global_scripts/GLOBAL_CONSTS'
import { ProtocolAPI } from '@/vue_apps/Protocols/entities/ProtocolAPI'

export declare interface IProtocolDraft {
  templateData: string
  templateHtml: string
  semdType: number
  printingTools: {
    entryInfo: boolean
    entryTitle: boolean
    clinicHeader: boolean
    doctorSignature: boolean
  }
}

export class ProtocolDraftStorage extends MExpirableCache {
  static KEY = 'protocolDraft'

  static DEFAULT_PROTOCOL_ID = 'new'

  static setProtocolValues (protocol: ProtocolAPI<any>, source: IProtocolDraft) {
    protocol.fillPrintingTools(source.printingTools)
    protocol.setValue('templateData', source.templateData)
    protocol.setValue('templateHtml', source.templateHtml)
    protocol.setValue('semdType', source.semdType)
  }

  constructor (protocolId?: number, clientId = gon.specific.client.id as number) {
    if (!clientId) {
      throw new Error('clientId is not defined')
    }

    const key = [
      ProtocolDraftStorage.KEY,
      clientId,
      protocolId || ProtocolDraftStorage.DEFAULT_PROTOCOL_ID,
    ].join('::')

    super(key, { timeout: GLOBAL_TIMES.ONE_WEEK })
  }

  cleanExpired () {
    super.cleanExpired(ProtocolDraftStorage.KEY)
  }
}
