import { addDeepSelectorListenerToElement } from '../_helpers/methods'
import { defaultSmsSettingsModal } from '../_helpers/defaults'
/**
 * В этом методе определены основные настройки смс (в виде объекта).
 * Для каждой создан свой cssClass и выводимый текст text
 *
 * Также для спец. случаев, определены свои блоки кода
 * @param modal
 */
export default function (modal) {
  const smsConfig = {
    appointment_confirmation: {
      cssClass: '.js_sms_disabled_appointment_confirmation',
      text: T.introduction.configurations.appointment_confirmation,
    },
    services_status: {
      cssClass: '.js_sms_disabled_services_status',
      text: T.introduction.configurations.services_status,
    },
    remind: {
      cssClass: '.js_sms_disabled_remind',
      text: T.introduction.configurations.remind,
    },
    notify: {
      cssClass: '.js_sms_disabled_notify',
      text: T.introduction.configurations.notify,
    },
  }

  /* Вызов заглушек выключенных настроек */
  Object.keys(smsConfig).forEach((item) => {
    addDeepSelectorListenerToElement(smsConfig[item].cssClass, (event) => {
      event.stopPropagation()
      $(smsConfig[item].cssClass).prop('checked', false)
      defaultSmsSettingsModal(modal, smsConfig[item].text)
    })
  })
}
