import formatter from '@/lib/formatters/formatter'

export const registerHandlebarsHelpers = () => {
  Handlebars.registerHelper('ifCond', function (v1, v2, options) {
    return v1 === v2 ? options.fn(this) : options.inverse(this)
  })

  Handlebars.registerHelper('ifNotEqual', function (v1, v2, options) {
    return v1 !== v2 ? options.fn(this) : options.inverse(this)
  })

  Handlebars.registerHelper('ifCanView', function (model, options) {
    const condition = Utils.can('view', model)

    return condition ? options.fn(this) : options.inverse(this)
  })

  Handlebars.registerHelper('ifCanManage', function (model, options) {
    const condition = Utils.can('manage', model)

    return condition ? options.fn(this) : options.inverse(this)
  })

  Handlebars.registerHelper('limit', function (arr, limit) {
    return arr.slice(0, limit)
  })

  Handlebars.registerHelper('if_greater_than', function (a, b, options) {
    return a > b ? options.fn(this) : options.inverse(this)
  })

  Handlebars.registerHelper('if_length_is_greater_than', function (a, b, options) {
    return a.length > b ? options.fn(this) : options.inverse(this)
  })

  Handlebars.registerHelper('shortName', function (surname, name, secondName) {
    return `${surname} ${name[0]}. ${secondName[0]}.`
  })

  Handlebars.registerHelper('fullName', function (surname, name, secondName) {
    return [surname, name, secondName].filter((val) => val.trim().length > 0).join(' ')
  })

  Handlebars.registerHelper('proxyPaymentKind', function (payment, kind) {
    let paymentVal = parseFloat(payment)
    switch (kind) {
      case 'order_refund':
      case 'cash_out':
      case 'cash_in_refund':
        paymentVal = -paymentVal
        break
    }

    return isNaN(paymentVal) ? '' : paymentVal.toFixed(2)
  })

  Handlebars.registerHelper('numMask', (num) => {
    return formatter.formatValue(num, [['number:formatNumberLocal', 0]])
  })

  Handlebars.registerHelper('currency', (num) => {
    return formatter.formatValue(num, ['currency'])
  })

  Handlebars.registerHelper('decimal', (num) => {
    return formatter.formatValue(num, ['decimal'])
  })

  Handlebars.registerHelper('currencyWithSign', (num) => {
    return formatter.formatValue(num, ['number:currencyWithSign'])
  })

  Handlebars.registerHelper('settingEnabled', function (setting, options) {
    return gon.application[setting] ? options.fn(this) : options.inverse(this)
  })
}
