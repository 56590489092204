const SKELETON_CLASS = 'vue-skeleton-span__hidden'

function unbind (el) {
  el.classList.remove(SKELETON_CLASS)
}

function bind (el, { value, oldValue }) {
  if (value) {
    if (el.classList.contains(SKELETON_CLASS)) { return }
    el.classList.add(SKELETON_CLASS)

    return
  }

  unbind(el)
}

export const skeletonDirective = {
  bind,
  update: bind,
  unbind,
}
