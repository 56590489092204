<script>
import tippy from 'tippy.js'

/**
 * Добавляет тултип обёрнутому элементу.
 * Дочерний элемент должен быть единственным и он всегда должен быть в DOM.
 * Чтобы условно отображать элементы с этим тултипом, нужно указать `v-if` на
 * `basic-tooltip`, а не на оборачиваемом элементе.
 */
export default {
  name: 'BasicTooltip',

  props: {
    text: {
      type: String,
      required: true,
    },
    showTooltip: {
      type: Boolean,
      default: true,
    },
    placement: {
      type: String,
      default: 'top-start',
    },
  },

  data () {
    return {
      tooltip: null,
    }
  },

  watch: {
    text (newText) {
      this.tooltip.setContent(newText)
    },
    showTooltip (shouldShow) {
      if (shouldShow) {
        if (!this.tooltip) {
          this.createTooltip()
        }
      } else {
        this.tooltip.destroy()
      }
    },
  },

  mounted () {
    if (this.showTooltip) {
      this.createTooltip()
    }
  },

  beforeDestroy () {
    if (this.tooltip) {
      this.tooltip.destroy()
    }
  },

  methods: {
    createTooltip () {
      if (this.$slots.default.length > 1) {
        throw new Error('Basic tooltip can have only 1 child element')
      }

      const config = Object.assign(
        {},
        Utils.tippyConfig,
        {
          content: this.text,
          placement: this.placement,
        },
      )
      this.tooltip = tippy(this.$slots.default[0].elm, config)
    },
  },
  render () {
    return this.$slots.default
  },
}
</script>
