import tippy from 'tippy.js'
import { isPlainObject } from 'lodash'

const instances = {}
window.tippyInstances = instances

function bind (el, { value, oldValue }) {
  const text = isPlainObject(value)
    ? value.text || value.tooltip
    : value

  const oldText = isPlainObject(oldValue)
    ? oldValue && oldValue.text
    : oldValue

  // иначе будет перерисовывать ВСЕ v-tooltip
  if (text === oldText) { return }

  if (el._tippy) { destroyInstance(el._tippy) }

  if (!text) { return }

  const tippyOptions = { content: text }
  if (value.placement) {
    tippyOptions.placement = value.placement
  }

  const tippyInstance = tippy(el, tippyOptions)
  instances[tippyInstance.id] = tippyInstance
}

function destroyInstance (tippyInstance = null) {
  if (!tippyInstance) { return }

  const id = tippyInstance.id
  tippyInstance.destroy()
  delete instances[id]
}

export default {
  bind,
  update: bind,
  unbind (el) {
    destroyInstance(el._tippy)
  },
}
