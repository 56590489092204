import _Vue from 'vue/dist/vue.esm'
import { extractModulesActivityFromGon } from '@/plugins/vue/medodsPlugin/extractModulesActivityFromGon'
import { IMedodsPluginData } from '@/plugins/vue/medodsPlugin/declarations/IMedodsPlugin.d'

const extractMedodsPluginData = (): IMedodsPluginData => {
  return {
    modules: extractModulesActivityFromGon(),
  }
}

export const medodsPlugin = {
  install (Vue: _Vue) {
    Vue.prototype.$m = extractMedodsPluginData()
  },
}
