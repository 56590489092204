import { IMedodsPluginDataModules } from '@/plugins/vue/medodsPlugin/declarations/IMedodsPluginDataModules'

export const extractModulesActivityFromGon = (): IMedodsPluginDataModules => {
  return {
    checkups: gon.application.checkups_module_enabled,
    clientWidget: gon.application.client_widget_enabled,
    dentalLabs: gon.application.dentalLaboratoriesEnabled,
    doctorArea: gon.application.doctor_area_module_enabled,
    egisz: gon.application.egisz_module_enabled,
    laboratories: gon.application.laboratoriesEnabled,
    marketing: gon.application.marketing_module_enabled,
    network: gon.application.network_module_enabled,
    nsiIdCardTypes: gon.application.nsi.idCardTypesDictionary,
    nsiProfessions: gon.application.nsi.professionsDictionary,
    nsiRegions: gon.application.nsi.russianSubjectsDictionary,
    smsDispatch: gon.application.sms_dispatch_module_enabled,
    sms: gon.application.sms_module_enabled,
    whatsapp: gon.application.whatsapp_module_enabled,
    workplaces: gon.application.workplaces_module_enabled,
  }
}
