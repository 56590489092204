import { createVueApp } from '@/vue_components/create_vue_app'
import LastAppointmentsStandalone
  from '@/vue_components/appointment/LastAppointmentsStandalone/LastAppointmentsStandalone.vue'

export const createLastAppointmentsStandalone = (el = '#last-appointments-modal-standalone') => {
  createVueApp({
    el,
    name: 'LastAppointmentsStandaloneApp',
    render: (h) => h(LastAppointmentsStandalone, {
      props: {
        needClinicInfo: gon.application.current_user_clinics?.length > 1,
      },
    }),
  })
}
