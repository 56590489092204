import introductionSms from './introduction/sms'
import introductionClientWidget from './introduction/client_widget'
import introductionTelephony from './introduction/telephony'

import disabledSettingsSms from './disabled_settings/sms'
import disabledSettingsTelephony from './disabled_settings/telephony'

/**
 * Точка входа
 * @param modal - Vue модалка (introduction_modal.js -> introductionModalBuilder)
 */
export default function (modal) {
  // отключенные настройки
  disabledSettingsSms(modal)
  disabledSettingsTelephony(modal)

  // отключенные модули
  introductionSms(modal)
  introductionClientWidget(modal)
  introductionTelephony(modal)
}
