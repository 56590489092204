import { addDeepSelectorListenerToElement } from '../_helpers/methods'
import { telephonyDisabled } from '../_helpers/elements'
import { defaultTelephonyModal } from '../_helpers/defaults'

/**
 * Вызов заглушки "Купи модуль" для телефонии
 * @param modal
 */
export default (modal) => {
  /* Вызов заглушки для модуля */
  addDeepSelectorListenerToElement(telephonyDisabled, (event) => {
    event.stopPropagation()
    $(telephonyDisabled).prop('checked', false)
    defaultTelephonyModal(modal)
  })
}
