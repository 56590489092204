<template>
  <el-popover
    :disabled="!manyClinics"
    placement="top-end"
    popper-class="dark dark-top menu"
  >
    <span
      slot="reference"
      v-tooltip="manyClinics && t('change_clinic')"
      :class="{ pointer: manyClinics }"
    >
      {{ currentClinic.title }}
    </span>

    <el-menu
      background-color="#475264"
      text-color="#FFFFFF"
      active-text-color="#FFFFFF"
    >
      <el-menu-item
        v-for="clinic in clinics"
        :key="`clinic: ${clinic.id}`"
        :disabled="clinic.id === currentClinic.id"
        @click="onMenuItemClick(clinic.id)"
      >
        <i class="fad fa-hospitals mr-5" />
        {{ clinic.title }}
      </el-menu-item>
    </el-menu>
  </el-popover>
</template>

<script>
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import { doctorPresenter } from '@/api_entities/doctors/doctorPresenter'
import { mapActions, mapGetters } from 'vuex'
import { DOCTOR_SCHEDULES_STORAGE } from '@/vue_components/doctor_schedules/consts'
import { MLocalStorage } from '@/_api/_storage/MLocalStorage'

export default {
  name: 'NavbarSelectClinic',

  props: {
    userId: PropsTypes.Number(undefined, true),
    currentClinic: PropsTypes.Object(undefined, true),
  },

  computed: {
    ...mapGetters('globalCatalogs/doctorStore', {
      clinics: 'vxGetDoctorClinics',
    }),

    manyClinics () {
      return this.clinics && this.clinics.length > 1
    },
  },

  created () {
    this.fetchClinics()
  },

  methods: {
    ...mapActions('globalCatalogs/doctorStore', {
      vxLoadClinics: 'vxLoadClinics',
    }),

    async fetchClinics () {
      await this.vxLoadClinics(this.userId)
    },

    async onMenuItemClick (clinicId) {
      if (gon.application.synchronous_change_current_clinic) {
        const storage = new MLocalStorage(DOCTOR_SCHEDULES_STORAGE)
        const storageData = storage.restore()

        storage.save({
          ...storageData,
          currentClinicId: clinicId,
        })
      }

      await doctorPresenter.selectClinic(this.userId, clinicId)
    },
  },
}
</script>
