import { addDeepSelectorListenerToElement } from '../_helpers/methods'
import { defaultTelephonySettingsModal } from '../_helpers/defaults'

/**
 * В этом методе определены основные настройки телефонии (в виде объекта).
 * Для каждой создан свой cssClass и выводимый текст text
 * @param modal
 */
export default function (modal) {
  const telephonyConfig = {
    uisConfirmation: {
      cssClass: '.js_disabled_uis_confirmation',
      text: T.introduction.configurations.uis_confirmation,
    },
    uisReminder: {
      cssClass: '.js_disabled_uis_reminder',
      text: T.introduction.configurations.uis_reminder,
    },
  }

  /* Вызов заглушек выключенных настроек */
  Object.keys(telephonyConfig).forEach((item) => {
    addDeepSelectorListenerToElement(telephonyConfig[item].cssClass, (event) => {
      event.stopPropagation()
      $(telephonyConfig[item].cssClass).prop('checked', false)
      defaultTelephonySettingsModal(modal, telephonyConfig[item].text)
    })
  })
}
