import { CLIENT_WIDGET_ICON, SMS_ICON, TELEPHONY_ICON } from './constants'

const SMS_URL = '/configuration/messages/general'
const TELEPHONY_URL = '/telephony/configurations'

/**
 * Вывод модального окна: "Купи модуль СМС"
 * @param modal
 */
export const defaultSmsModal = (modal) => {
  modal.setDataAndShow(SMS_ICON, t('introduction.sms'))
}

/**
 * Вывод модального окна: "Купи модуль Онлайн-запись"
 * @param modal
 */
export const defaultClientWidgetModal = (modal) => {
  modal.setDataAndShow(CLIENT_WIDGET_ICON, t('introduction.client_widget'))
}

/**
 * Вывод модального окна: "Купи модуль телефония"
 * @param modal
 */
export const defaultTelephonyModal = (modal) => {
  modal.setDataAndShow(TELEPHONY_ICON, t('introduction.telephony'))
}

/**
 * Вывод модального окна: "Включи настройку {text} в настройках СМС"
 * @param modal
 * @param text {String}
 */
export const defaultSmsSettingsModal = (modal, text) => {
  const defaultSmsSettings = (text) => [
    SMS_ICON,
    text,
    t('introduction.configurations.not_active'),
    { text: t('introduction.configurations.move_to_configuration'), url: SMS_URL },
  ]

  modal.setDataAndShow(...defaultSmsSettings(text))
}

/**
 * Вывод модального окна: "Включи настройку {text} в настройках Телефонии"
 * @param modal
 * @param text {String}
 */
export const defaultTelephonySettingsModal = (modal, text) => {
  const defaultTelephonySettings = (text) => [
    TELEPHONY_ICON,
    text,
    t('introduction.configurations.not_active'),
    { text: t('introduction.configurations.move_to_configuration'), url: TELEPHONY_URL },
  ]

  modal.setDataAndShow(...defaultTelephonySettings(text))
}
