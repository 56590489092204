import CheckBrowser from '@/vue_components/CheckBrowser/CheckBrowser'

export const checkBrowser = () => {
  if (!Utils.isInvalidBrowser()) { return }

  Utils.initVueInBody({
    el: '#vue_check_browser',
    name: 'CheckBrowserRoot',
    render: (h) => h(CheckBrowser),
  })

  throw new Error('Your browser not supported')
}
