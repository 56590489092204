import { addDeepSelectorListenerToElement } from '../_helpers/methods'
import { defaultClientWidgetModal } from '../_helpers/defaults'
import { clientWidgetDisabled } from '../_helpers/elements'

/**
 * Вызов заглушки "Купи модуль" для виджета
 * @param modal
 */
export default (modal) => {
  /* Вызов заглушки для модуля */
  addDeepSelectorListenerToElement(clientWidgetDisabled, (event) => {
    event.stopPropagation()
    $(clientWidgetDisabled).prop('checked', false)
    defaultClientWidgetModal(modal)
  })
}
