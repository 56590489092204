import { addDeepSelectorListenerToElement } from '../_helpers/methods'
import { smsDisabled } from '../_helpers/elements'
import { defaultSmsModal } from '../_helpers/defaults'

/**
 * Вызов заглушки "Купи модуль" для смс
 * @param modal
 */
export default (modal) => {
  /* Вызов заглушки для модуля */
  addDeepSelectorListenerToElement(smsDisabled, (event) => {
    event.stopPropagation()
    $(smsDisabled).prop('checked', false)
    defaultSmsModal(modal)
  })
}
