<template>
  <modal
    id="last-appointments-modal-standalone"
    :modal-visibility="modalVisible"
    modal-class="modal-xl"
    @close="onLastAppointmentsClose"
  >
    <template #header>
      <span class="fad fa-tasks" />
      {{ t('reception.last_appointments') }}
    </template>

    <template #body>
      <last-appointments
        v-if="client"
        :client="client"
        :appointments="appointments"
        :selected-appointments.sync="selectedAppointments"
        :need-clinic-info="needClinicInfo"
        @close-modal="onLastAppointmentsClose"
        @create="createAppointmentFromLastAppointments"
        @move="moveLastAppointment"
        @edit="loadEditAppointmentForm"
        @cancel="cancelLastAppointment"
      />
    </template>

    <template #footer-left>
      <button
        class="btn btn-warning btn-with-icon modal-close"
        :disabled="!selectedAppointmentsCount"
        @click="createLastAppointmentsOrder"
      >
        <span class="btn-with-icon_icon fad fa-cart-plus" />
        <span class="btn-with-icon_text">
          {{ t('add_order') }}
        </span>
      </button>
    </template>

    <template #footer-right>
      <button
        :disabled="loading"
        class="btn btn-primary btn-with-icon modal-close"
        type="button"
        @click="onLastAppointmentsClose"
      >
        <span class="btn-with-icon_icon fad fa-times" />
        <span class="btn-with-icon_text">
          {{ t('close') }}
        </span>
      </button>
    </template>
  </modal>
</template>

<script>
import { defineComponent } from 'vue'
import LastAppointments from '@/vue_components/appointment/last_appointments.vue'
import Modal from '@/vue_components/modal.vue'
import { Intent } from '@/services/intents'
import { MODE_CLIENT_INSERT } from '@/plugins/schedule/scheduleCommon/const'
import { fetchAppointmentByClient, updateAppointmentStatus } from '@/vue_components/doctor_schedules/rest'
import { APPOINTMENT_STATUS } from '@/vue_components/appointment/const'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { MConfirm } from '@/vue_present/_base/MConfirm/MConfirm'

export default defineComponent({
  name: 'LastAppointmentsStandalone',
  components: { Modal, LastAppointments },
  mixins: [SpinnerHolder],

  props: {
    needClinicInfo: Boolean,
  },

  data () {
    return {
      modalVisible: false,

      client: null,

      appointments: [],
      selectedAppointments: [],
    }
  },

  computed: {
    selectedAppointmentsCount () {
      return this.selectedAppointments.length
    },
  },

  created () {
    this.$pubSub.subscribe('LastAppointments.open', (clientId) => {
      this.chooseLastAppointmentClient(clientId)
    })
  },

  methods: {
    setDataToDefault () {
      this.selectedAppointments = []
      this.appointments = []
      this.client = null
    },

    onLastAppointmentsClose () {
      this.modalVisible = false
      this.setDataToDefault()
    },

    chooseLastAppointmentClient (clientId) {
      this.setDataToDefault()

      const promise = fetchAppointmentByClient(clientId)
        .then((response) => {
          this.appointments = response.appointments
          this.client = response.client

          this.modalVisible = true
        })

      this.withSpinner(promise)
    },

    openDoctorSchedules (intentAction, params) {
      const newWindow = window.open(Routes.doctor_schedules_path(), '_blank')
      Intent.setIntent(newWindow, intentAction, params)
    },

    createAppointmentFromLastAppointments (client) {
      this.openDoctorSchedules(Intent.SCHEDULE_APPOINTMENT_CREATE, {
        clientId: client.id,
        mode: MODE_CLIENT_INSERT,
      })
    },

    moveLastAppointment (appointment) {
      this.openDoctorSchedules(Intent.SCHEDULE_APPOINTMENT_MOVE, { appointment })
    },

    loadEditAppointmentForm (appointment) {
      this.openDoctorSchedules(Intent.SCHEDULE_APPOINTMENT_EDIT, { appointment })
    },

    async cancelLastAppointment (appointment) {
      const confirm = await MConfirm.warning(t('reception.do_you_really_want_to_cancel_appointment'))
      if (!confirm) { return }

      updateAppointmentStatus({ id: appointment.id, status: APPOINTMENT_STATUS.CANCELED })
        .then(() => {
          appointment.status = APPOINTMENT_STATUS.CANCELED
        })
        .catch(Utils.reportError('updateAppointmentStatus'))
    },

    createLastAppointmentsOrder () {
      if (!this.selectedAppointmentsCount) { return }
      Turbolinks.visit(Routes.new_order_path({
        appointment_ids: this.selectedAppointments,
        client_id: this.client.id,
      }))
    },
  },
})
</script>
