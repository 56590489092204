import formatter from '../../lib/formatters/formatter'

export default function (Vue) {
  Vue.filter('price', (value) => formatter.formatValue(value, ['price']))

  Vue.filter('currency', (value) => formatter.formatValue(value, ['number:currencyWithSign']))

  Vue.filter('date', (value) => formatter.formatValue(value, ['date:localDate']))

  Vue.filter('route', (value, route) => {
    if (!Routes[route]) {
      return ''
    }

    return Routes[route](value)
  })
}
