export default {
  install (Vue) {
    /**
     *
     * @param {string} prop
     * @param {*} value
     */
    Vue.prototype.$updateSync = function (prop, value) {
      this.$emit(`update:${prop}`, value)
    }

    Vue.prototype.$updateSyncGC = function (prop, value) {
      this.$emit('updateSyncGC', { prop, value })
    }
  },
}
