<template>
  <m-modal
    :visible="true"
    :dialog-title="t('browser_not_supported')"
    :can-close="false"
    width="600px"
  >
    <template #body>
      <span>
        {{ t('work_programm_need_google') }} <a href="https://www.google.com/chrome/browser/desktop/">{{ t('reference_1') }}</a>
      </span>
    </template>

    <template #footer>
      <span class="prompt-notice">
        <span>{{ t('reference_2') }}</span>
        <a href="https://medods.ru/post/tehnicheskie-trebovaniya">{{ t('reference_3') }}</a>
      </span>
    </template>
  </m-modal>
</template>

<script>
import MModal from '@/vue_present/_base/MModal/MModal'

export default {
  name: 'CheckBrowser',
  components: { MModal },
}
</script>
