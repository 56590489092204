/**
 * Метод для добавления обработчика (method) клика  на elementName
 * @param elem {String|Array} - имя элемента(-ов)
 * @param method {function} - метод, который выполнится при нажатии
 */
export const addEventListenerToElement = (elem, method) => {
  if (Array.isArray(elem)) {
    elem.forEach((item) => { $(item).on('click', method) })
  } else if (typeof elem === 'string') {
    $(elem).on('click', method)
  }
}

/**
 * Метод для добавления обработчика (method) клика для динамических элементов
 * @param elem {String|Array} - имя элемента(-ов)
 * @param method {function} - метод, который выполнится при нажатии
 * @param rootElement {String} - корневой элемент (default: 'body'), на который вешается событие
 */
export const addDeepSelectorListenerToElement = (elem, method, rootElement = 'body') => {
  if (Array.isArray(elem)) {
    elem.forEach((item) => { $(rootElement).on('click', item, method) })
  } else if (typeof elem === 'string') {
    $(rootElement).on('click', elem, method)
  }
}
