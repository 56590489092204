import { DoctorClinicsCache } from '@/api_entities/doctors/DoctorClinicsCache/DoctorClinicsCache'
import { MLocalStorage } from '@/_api/_storage/MLocalStorage'
import { ProtocolDraftStorage } from '@/plugins/dynamic_forms/components/editor/ProtocolDraftStorage'

export const onLogout = () => {
  const navbarLogout = document.querySelector('.navbar-logout')
  if (!navbarLogout) { return }

  navbarLogout.removeEventListener('click', null)

  navbarLogout.addEventListener('click', clearCache)
}

const clearCache = () => {
  try {
    new DoctorClinicsCache(gon.application.current_user?.id).drop()
  } catch (e) {}

  try {
    new MLocalStorage('NSIIdCardTypes').drop()
  } catch (e) {}

  try {
    new MLocalStorage('NSIRussianSubjects').drop()
  } catch (e) {}

  try {
    new ProtocolDraftStorage().cleanExpired()
  } catch (e) {}
}
