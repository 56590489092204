export const registerValidator = {
  install (Vue) {
    /**
     * @param {*} value
     */
    Vue.prototype.$registerValidator = function (value) {
      this.$emit('registerValidator', value)
    }
  },
}
