import { createVueApp } from '@/vue_components/create_vue_app'
import NavbarSelectClinic from '@/vue_components/users/NavbarSelectClinic/NavbarSelectClinic'
import { pick } from 'lodash'
import { createStore } from '@/vue_components/store/store'

export const createNavbarSelectClinic = (el = '#vue_clinic_selector_in_navbar') => {
  const userId = gon.application.current_user && gon.application.current_user.id
  if (!userId) { return }

  createVueApp({
    el,
    name: 'NavbarSelectClinicRoot',
    store: createStore(),
    render: (h) => h(NavbarSelectClinic, {
      props: {
        userId,
        currentClinic: { ...pick(gon.application.current_clinic, ['id', 'title']) },
      },
    }),
  })
}
